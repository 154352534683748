import { Injectable, inject } from "@angular/core";
import { Router, NavigationEnd, Event } from "@angular/router";
import { filter } from "rxjs/operators";
import { LanguageService } from "../core/services/language.service";
import { SearchService } from "../services/search.service";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  private router = inject(Router);
  private languageService = inject(LanguageService);
  private searchService = inject(SearchService);

  private lastNonCartUrl: string = "/";

  constructor() {
    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: Event) => {
        const navEndEvent = event as NavigationEnd;
        if (!navEndEvent.urlAfterRedirects.includes("/cart")) {
          this.lastNonCartUrl = navEndEvent.urlAfterRedirects;
        }
      });
  }

  goBack() {
    this.router.navigateByUrl(this.lastNonCartUrl);
  }

  async goToSearch(showSearchBox: boolean) {
    // if we're on the search page, don't navigate
    if (this.router.url.includes("/search") == false) {
      await this.router.navigateByUrl(
        `/${this.languageService.currentLanguage()}/search`
      );
    }
    this.searchService.focusSeachBox.next(true);
    this.searchService.showSearchBox.next(showSearchBox);
  }

  getInstagramLink() {
    return "https://www.instagram.com/jovis_verlag/";
  }

  getFacebookLink() {
    const lang = this.languageService.currentLanguage();
    if (lang === "de") {
      return "https://de-de.facebook.com/JOVISVerlag/";
    } else {
      return "https://en-gb.facebook.com/JOVISVerlag/";
    }
  }

  getPrivacyPolicyLink() {
    const lang = this.languageService.currentLanguage();
    if (lang === "de") {
      return "https://www.degruyter.com/publishing/datenschutzerklaerung?lang=de";
    } else {
      return "https://www.degruyter.com/publishing/our-privacy-policy?lang=en";
    }
  }

  getTermsAndConditionsLink() {
    const lang = this.languageService.currentLanguage();
    if (lang === "de") {
      return "https://www.degruyter.com/publishing/agb?lang=de";
    } else {
      return "https://www.degruyter.com/publishing/terms-and-conditions?lang=en";
    }
  }

  getCookiePolicyLink() {
    const lang = this.languageService.currentLanguage();
    if (lang === "de") {
      return "https://www.degruyter.com/publishing/unsere-cookie-richtlinien?lang=de";
    } else {
      return "https://www.degruyter.com/publishing/our-cookie-policy?lang=en";
    }
  }
}
