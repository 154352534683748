import { Location } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { NavigationService } from "src/app/shared/navigation.service";
import { TranslateModule } from "@ngx-translate/core";
import { InlineSVGModule } from "ng-inline-svg-w";

@Component({
  selector: "app-back",
  templateUrl: "./back.component.html",
  styleUrls: ["./back.component.scss"],
  standalone: true,
  imports: [TranslateModule, InlineSVGModule],
})
export class BackComponent {
  private location = inject(Location);
  private navigationService = inject(NavigationService);

  constructor() {}

  @Input() text: string = "GLOBALS.back";
  @Input() backToNonCartPage: boolean = false;

  goBack() {
    if (this.backToNonCartPage) {
      this.navigationService.goBack();
    } else {
      this.location.back();
    }
  }
}
